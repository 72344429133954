<template>
  <div>
    <data-filter :get-filter-data="getReport" />
    <loading-report v-if="loadingReport === true" />
    <report-summary
      v-if="reportData.reportData"
      :report-data="reportData.reportData"
      :totals-data="reportData.totals"
    />
  </div>
</template>

<script>
import DataFilter from '@/views/Reports/Insurance/Production/DataFilter.vue'
import ReportSummary from '@/views/Reports/Insurance/Production/Summary.vue'
import LoadingReport from '@/views/Reports/Insurance/Production/LoadingReport.vue'

export default {
  name: 'Production',
  components: {
    DataFilter,
    ReportSummary,
    LoadingReport,
  },
  computed: {
    filterData() {
      return this.$store.getters['insuranceProductionReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['insuranceProductionReport/getReport']
    },
    loadingReport() {
      return this.$store.getters['insuranceProductionReport/getLoading']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('insuranceProductionReport/users')
    },
    getReport() {
      if (this.filterData.sdate || this.filterData.edate) {
        this.$store.dispatch('insuranceProductionReport/report', this.filterData)
      }
    },
  },
}
</script>

<style scoped>

</style>
