<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Rapor Özeti</b-card-title>
    </b-card-header>
    <b-table
      striped
      hover
      responsive
      :fields="fields"
      :items="reportData"
    >
      <template #cell(text)="data">
        <b-button
          variant="flat-success"
          class="btn-icon rounded-circle"
          :disabled="!data.item.details.data"
          @click="setModal(data.item.text,data.item.details)"
        >
          <FeatherIcon icon="EyeIcon" />
        </b-button>
        {{ data.item.text }}
      </template>
      <template #cell(net_prim)="data">
        {{ data.item.net_prim | toCurrency }} ₺
      </template>
      <template #cell(commission)="data">
        {{ data.item.commission | toCurrency }} ₺
      </template>
      <template #cell(commission_rate)="data">
        % {{ data.item.commission_rate | toNumber }}
      </template>
      <template #custom-foot>
        <b-tr>
          <b-th>
            Toplam
          </b-th>
          <b-th class="text-right">
            {{ totalsData.net_prim | toCurrency }} ₺
          </b-th>
          <b-th class="text-right">
            {{ totalsData.commission | toCurrency }} ₺
          </b-th>
          <b-th class="text-right">
            % {{ totalsData.commission_rate | toNumber }}
          </b-th>
        </b-tr>
      </template>
    </b-table>
    <b-modal
      id="detail-modal"
      v-model="detailModal"
      centered
      size="xl"
      :title="modalData.title"
      hide-footer
      scrollable
    >
      <b-table
        striped
        hover
        responsive
        :fields="modalFields"
        :items="modalData.data"
      >
        <template #cell(temsilci)="data">
          {{ (data.item.temsilci)? data.item.temsilci : 'Tanımsız' }}
        </template>
        <template #cell(net_prim)="data">
          {{ data.item.net_prim | toCurrency }} ₺
        </template>
        <template #cell(commission)="data">
          {{ data.item.commission | toCurrency }} ₺
        </template>
        <template #cell(commission_rate)="data">
          % {{ data.item.commission_rate | toNumber }}
        </template>
        <template #custom-foot>
          <b-tr>
            <b-th>
              Toplam
            </b-th>
            <b-th class="text-right">
              {{ modalData.totals.net_prim | toCurrency }} ₺
            </b-th>
            <b-th class="text-right">
              {{ modalData.totals.commission | toCurrency }} ₺
            </b-th>
            <b-th class="text-right">
              % {{ modalData.totals.commission_rate | toNumber }}
            </b-th>
          </b-tr>
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardHeader, BCardTitle, BTr, BTh, BButton, BModal,
} from 'bootstrap-vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BTr,
    BTh,
    BButton,
    BModal,
  },
  props: {
    reportData: {
      type: Array,
      required: true,
    },
    totalsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      detailModal: false,
      modalData: {
        title: 'Detay',
        data: [],
        totals: {},
      },
      fields: [
        {
          key: 'text', label: 'AÇIKLAMA', sortable: true,
        },
        {
          key: 'net_prim', label: 'NET PRİM', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'commission', label: 'ACENTE KOMİSYONU', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'commission_rate', label: 'KOMİSYON ORANI', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
      ],
      modalFields: [
        {
          key: 'temsilci', label: 'TEMSİLCİ', sortable: true,
        },
        {
          key: 'net_prim', label: 'NET PRİM', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'commission', label: 'ACENTE KOMİSYONU', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
        {
          key: 'commission_rate', label: 'KOMİSYON ORANI', sortable: true, thClass: 'text-right width-250', tdClass: 'text-right',
        },
      ],
    }
  },
  methods: {
    setModal(text, data) {
      this.modalData.title = text
      this.modalData.data = data.data
      this.modalData.totals = data.totals
      this.detailModal = true
    },
  },
}
</script>

<style scoped>

</style>
