<template>
  <b-card>
    <b-row>
      <b-col>
        <v-select
          v-model="filterData.username"
          :options="users"
          label="username"
          :reduce="user => user.username"
          placeholder="Temsilci"
        />
      </b-col>
      <b-col>
        <b-form-datepicker
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col>
        <b-form-datepicker
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          :disabled="!filterData.sdate || !filterData.edate"
          @click="getFilterData"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterData',
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  props: {
    getFilterData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['insuranceProductionReport/getFilterData']
    },
    users() {
      return this.$store.getters['insuranceProductionReport/getUsers']
    },
  },
}
</script>
